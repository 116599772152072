/* tslint:disable */
/* eslint-disable */
/**
 * Oogst - Franchise Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
// @ts-ignore
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ActiveCompanyRequest } from '../models';
// @ts-ignore
import type { BulkMutation } from '../models';
// @ts-ignore
import type { BulkMutationV2 } from '../models';
// @ts-ignore
import type { Categories } from '../models';
// @ts-ignore
import type { Farmer } from '../models';
// @ts-ignore
import type { FarmerCreateRequest } from '../models';
// @ts-ignore
import type { Mutation } from '../models';
// @ts-ignore
import type { Product } from '../models';
// @ts-ignore
import type { Store } from '../models';
// @ts-ignore
import type { StoreCreateRequest } from '../models';
// @ts-ignore
import type { StoreProduct } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * todo
         * @param {Array<BulkMutation>} bulkMutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkMutations: async (bulkMutation: Array<BulkMutation>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkMutation' is not null or undefined
            assertParamExists('createBulkMutations', 'bulkMutation', bulkMutation)
            const localVarPath = `/mutations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkMutation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * todo
         * @param {Array<BulkMutationV2>} bulkMutationV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkMutationsV2: async (bulkMutationV2: Array<BulkMutationV2>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkMutationV2' is not null or undefined
            assertParamExists('createBulkMutationsV2', 'bulkMutationV2', bulkMutationV2)
            const localVarPath = `/mutationsV2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkMutationV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FarmerCreateRequest} farmerCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFarmer: async (farmerCreateRequest: FarmerCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'farmerCreateRequest' is not null or undefined
            assertParamExists('createFarmer', 'farmerCreateRequest', farmerCreateRequest)
            const localVarPath = `/farmers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(farmerCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (product: Product, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('createProduct', 'product', product)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StoreCreateRequest} storeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore: async (storeCreateRequest: StoreCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeCreateRequest' is not null or undefined
            assertParamExists('createStore', 'storeCreateRequest', storeCreateRequest)
            const localVarPath = `/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFarmer: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFarmer', 'id', id)
            const localVarPath = `/farmers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProduct', 'id', id)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStore', 'id', id)
            const localVarPath = `/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFarmer: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFarmer', 'id', id)
            const localVarPath = `/farmers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFarmers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/farmers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [storeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleProductStockRecords: async (ids: Array<string>, storeId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getMultipleProductStockRecords', 'ids', ids)
            const localVarPath = `/products/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Mutation} mutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationUsers: async (id: string, mutation: Mutation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganisationUsers', 'id', id)
            // verify required parameter 'mutation' is not null or undefined
            assertParamExists('getOrganisationUsers', 'mutation', mutation)
            const localVarPath = `/products/{id}/mutation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mutation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProduct', 'id', id)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {string} [category] 
         * @param {string} [supplier] 
         * @param {string} [farmer] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductExport: async (xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSTOREID' is not null or undefined
            assertParamExists('getProductExport', 'xSTOREID', xSTOREID)
            const localVarPath = `/products/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (supplier !== undefined) {
                localVarQueryParameter['supplier'] = supplier;
            }

            if (farmer !== undefined) {
                localVarQueryParameter['farmer'] = farmer;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (xSTOREID != null) {
                localVarHeaderParameter['X-STORE-ID'] = String(xSTOREID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {string} [category] 
         * @param {string} [supplier] 
         * @param {string} [farmer] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockBelowTargetExport: async (xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSTOREID' is not null or undefined
            assertParamExists('getProductStockBelowTargetExport', 'xSTOREID', xSTOREID)
            const localVarPath = `/products/export/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (supplier !== undefined) {
                localVarQueryParameter['supplier'] = supplier;
            }

            if (farmer !== undefined) {
                localVarQueryParameter['farmer'] = farmer;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (xSTOREID != null) {
                localVarHeaderParameter['X-STORE-ID'] = String(xSTOREID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockRecords: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductStockRecords', 'id', id)
            const localVarPath = `/products/{id}/stock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {number} [p] 
         * @param {string} [category] 
         * @param {string} [brand] 
         * @param {string} [farmer] 
         * @param {string} [filter] Filter string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (xSTOREID: string, p?: number, category?: string, brand?: string, farmer?: string, filter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSTOREID' is not null or undefined
            assertParamExists('getProducts', 'xSTOREID', xSTOREID)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (p !== undefined) {
                localVarQueryParameter['p'] = p;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (farmer !== undefined) {
                localVarQueryParameter['farmer'] = farmer;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (xSTOREID != null) {
                localVarHeaderParameter['X-STORE-ID'] = String(xSTOREID);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStore: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStore', 'id', id)
            const localVarPath = `/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActiveCompanyRequest} activeCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveCompany: async (activeCompanyRequest: ActiveCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeCompanyRequest' is not null or undefined
            assertParamExists('updateActiveCompany', 'activeCompanyRequest', activeCompanyRequest)
            const localVarPath = `/user/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Farmer} farmer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFarmer: async (id: string, farmer: Farmer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFarmer', 'id', id)
            // verify required parameter 'farmer' is not null or undefined
            assertParamExists('updateFarmer', 'farmer', farmer)
            const localVarPath = `/farmers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(farmer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: string, product: Product, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProduct', 'id', id)
            // verify required parameter 'product' is not null or undefined
            assertParamExists('updateProduct', 'product', product)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<StoreProduct>} storeProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStockRecords: async (id: string, storeProduct: Array<StoreProduct>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProductStockRecords', 'id', id)
            // verify required parameter 'storeProduct' is not null or undefined
            assertParamExists('updateProductStockRecords', 'storeProduct', storeProduct)
            const localVarPath = `/products/{id}/stock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {StoreCreateRequest} storeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStore: async (id: string, storeCreateRequest: StoreCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateStore', 'id', id)
            // verify required parameter 'storeCreateRequest' is not null or undefined
            assertParamExists('updateStore', 'storeCreateRequest', storeCreateRequest)
            const localVarPath = `/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * todo
         * @param {Array<BulkMutation>} bulkMutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBulkMutations(bulkMutation: Array<BulkMutation>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkMutation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBulkMutations(bulkMutation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createBulkMutations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * todo
         * @param {Array<BulkMutationV2>} bulkMutationV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBulkMutationsV2(bulkMutationV2: Array<BulkMutationV2>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkMutationV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBulkMutationsV2(bulkMutationV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createBulkMutationsV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FarmerCreateRequest} farmerCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFarmer(farmerCreateRequest: FarmerCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Farmer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFarmer(farmerCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createFarmer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(product: Product, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(product, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StoreCreateRequest} storeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStore(storeCreateRequest: StoreCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStore(storeCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createStore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFarmer(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFarmer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteFarmer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStore(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStore(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteStore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFarmer(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Farmer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFarmer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getFarmer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFarmers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Farmer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFarmers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getFarmers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [storeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMultipleProductStockRecords(ids: Array<string>, storeId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoreProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMultipleProductStockRecords(ids, storeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMultipleProductStockRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {Mutation} mutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisationUsers(id: string, mutation: Mutation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Mutation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationUsers(id, mutation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getOrganisationUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {string} [category] 
         * @param {string} [supplier] 
         * @param {string} [farmer] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductExport(xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductExport(xSTOREID, category, supplier, farmer, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getProductExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {string} [category] 
         * @param {string} [supplier] 
         * @param {string} [farmer] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductStockBelowTargetExport(xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductStockBelowTargetExport(xSTOREID, category, supplier, farmer, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getProductStockBelowTargetExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductStockRecords(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoreProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductStockRecords(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getProductStockRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {number} [p] 
         * @param {string} [category] 
         * @param {string} [brand] 
         * @param {string} [farmer] 
         * @param {string} [filter] Filter string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(xSTOREID: string, p?: number, category?: string, brand?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(xSTOREID, p, category, brand, farmer, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStore(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStore(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getStore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStores(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStores(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getStores']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ActiveCompanyRequest} activeCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActiveCompany(activeCompanyRequest: ActiveCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActiveCompany(activeCompanyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateActiveCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {Farmer} farmer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFarmer(id: string, farmer: Farmer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Farmer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFarmer(id, farmer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateFarmer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: string, product: Product, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(id, product, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<StoreProduct>} storeProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductStockRecords(id: string, storeProduct: Array<StoreProduct>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductStockRecords(id, storeProduct, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateProductStockRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {StoreCreateRequest} storeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStore(id: string, storeCreateRequest: StoreCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStore(id, storeCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateStore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * todo
         * @param {Array<BulkMutation>} bulkMutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkMutations(bulkMutation: Array<BulkMutation>, options?: RawAxiosRequestConfig): AxiosPromise<Array<BulkMutation>> {
            return localVarFp.createBulkMutations(bulkMutation, options).then((request) => request(axios, basePath));
        },
        /**
         * todo
         * @param {Array<BulkMutationV2>} bulkMutationV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkMutationsV2(bulkMutationV2: Array<BulkMutationV2>, options?: RawAxiosRequestConfig): AxiosPromise<Array<BulkMutationV2>> {
            return localVarFp.createBulkMutationsV2(bulkMutationV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FarmerCreateRequest} farmerCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFarmer(farmerCreateRequest: FarmerCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Farmer> {
            return localVarFp.createFarmer(farmerCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(product: Product, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.createProduct(product, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StoreCreateRequest} storeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore(storeCreateRequest: StoreCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Store> {
            return localVarFp.createStore(storeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFarmer(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFarmer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteStore(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: RawAxiosRequestConfig): AxiosPromise<Categories> {
            return localVarFp.getCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFarmer(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Farmer> {
            return localVarFp.getFarmer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFarmers(options?: RawAxiosRequestConfig): AxiosPromise<Array<Farmer>> {
            return localVarFp.getFarmers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [storeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleProductStockRecords(ids: Array<string>, storeId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StoreProduct>> {
            return localVarFp.getMultipleProductStockRecords(ids, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Mutation} mutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationUsers(id: string, mutation: Mutation, options?: RawAxiosRequestConfig): AxiosPromise<Array<Mutation>> {
            return localVarFp.getOrganisationUsers(id, mutation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.getProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {string} [category] 
         * @param {string} [supplier] 
         * @param {string} [farmer] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductExport(xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getProductExport(xSTOREID, category, supplier, farmer, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {string} [category] 
         * @param {string} [supplier] 
         * @param {string} [farmer] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockBelowTargetExport(xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getProductStockBelowTargetExport(xSTOREID, category, supplier, farmer, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStockRecords(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StoreProduct>> {
            return localVarFp.getProductStockRecords(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xSTOREID 
         * @param {number} [p] 
         * @param {string} [category] 
         * @param {string} [brand] 
         * @param {string} [farmer] 
         * @param {string} [filter] Filter string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(xSTOREID: string, p?: number, category?: string, brand?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Product>> {
            return localVarFp.getProducts(xSTOREID, p, category, brand, farmer, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStore(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Store>> {
            return localVarFp.getStore(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores(options?: RawAxiosRequestConfig): AxiosPromise<Array<Store>> {
            return localVarFp.getStores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActiveCompanyRequest} activeCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveCompany(activeCompanyRequest: ActiveCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateActiveCompany(activeCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Farmer} farmer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFarmer(id: string, farmer: Farmer, options?: RawAxiosRequestConfig): AxiosPromise<Farmer> {
            return localVarFp.updateFarmer(id, farmer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(id: string, product: Product, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.updateProduct(id, product, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<StoreProduct>} storeProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStockRecords(id: string, storeProduct: Array<StoreProduct>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateProductStockRecords(id, storeProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {StoreCreateRequest} storeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStore(id: string, storeCreateRequest: StoreCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Store> {
            return localVarFp.updateStore(id, storeCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * todo
     * @param {Array<BulkMutation>} bulkMutation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createBulkMutations(bulkMutation: Array<BulkMutation>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createBulkMutations(bulkMutation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * todo
     * @param {Array<BulkMutationV2>} bulkMutationV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createBulkMutationsV2(bulkMutationV2: Array<BulkMutationV2>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createBulkMutationsV2(bulkMutationV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FarmerCreateRequest} farmerCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createFarmer(farmerCreateRequest: FarmerCreateRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createFarmer(farmerCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Product} product 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProduct(product: Product, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createProduct(product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoreCreateRequest} storeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createStore(storeCreateRequest: StoreCreateRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createStore(storeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteFarmer(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteFarmer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProduct(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStore(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteStore(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCategories(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFarmer(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFarmer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFarmers(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFarmers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} ids 
     * @param {string} [storeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMultipleProductStockRecords(ids: Array<string>, storeId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMultipleProductStockRecords(ids, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Mutation} mutation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOrganisationUsers(id: string, mutation: Mutation, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOrganisationUsers(id, mutation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProduct(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xSTOREID 
     * @param {string} [category] 
     * @param {string} [supplier] 
     * @param {string} [farmer] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductExport(xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProductExport(xSTOREID, category, supplier, farmer, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xSTOREID 
     * @param {string} [category] 
     * @param {string} [supplier] 
     * @param {string} [farmer] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductStockBelowTargetExport(xSTOREID: string, category?: string, supplier?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProductStockBelowTargetExport(xSTOREID, category, supplier, farmer, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductStockRecords(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProductStockRecords(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xSTOREID 
     * @param {number} [p] 
     * @param {string} [category] 
     * @param {string} [brand] 
     * @param {string} [farmer] 
     * @param {string} [filter] Filter string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProducts(xSTOREID: string, p?: number, category?: string, brand?: string, farmer?: string, filter?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProducts(xSTOREID, p, category, brand, farmer, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStore(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStore(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStores(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActiveCompanyRequest} activeCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateActiveCompany(activeCompanyRequest: ActiveCompanyRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateActiveCompany(activeCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Farmer} farmer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateFarmer(id: string, farmer: Farmer, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateFarmer(id, farmer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Product} product 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProduct(id: string, product: Product, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProduct(id, product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<StoreProduct>} storeProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProductStockRecords(id: string, storeProduct: Array<StoreProduct>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProductStockRecords(id, storeProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {StoreCreateRequest} storeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateStore(id: string, storeCreateRequest: StoreCreateRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateStore(id, storeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

